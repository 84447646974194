<template>
  <div>
    <div class="header">
      <div class="header_top">
        <img src="../../assets/user.png" alt />
        <span class="center">薪酬管理</span>
        <img src="../../assets/right.png" alt />
        <span class="center">工资单列表</span>
      </div>
      <div style="display: flex; align-items: center">
        <div style="display: flex; align-items: center">
          <span class="seach_text">关键字：</span>
          <el-input
            placeholder="请输入人员姓名"
            v-model="keywords"
            @input="query"
            size="mini"
            style="margin-right: 10px; width: 300px"
            prefix-icon="el-icon-search"
            clearable
          ></el-input>
        </div>
        <div style="display: flex; align-items: center">
          <span class="seach_text">日期：</span>
          <el-date-picker
            v-model="value"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            placeholder="选择日期时间"
            end-placeholder="结束日期"
            format="yyyy-MM-dd"
            size="mini"
            @change="getrq(value)"
          ></el-date-picker>
        </div>
        <el-button style="margin-left:20px" type="primary" size="small" @click="exportAll">导出</el-button>
        <el-button style="margin-left:20px" type="primary" size="small" @click="getAdd">新建</el-button>
      </div>
    </div>
    <el-table :data="tableData" style="width: 100%">
      <el-table-column label="年月">
        <template slot-scope="scope">
          <span>{{ scope.row.date }}</span>
        </template>
      </el-table-column>
      <el-table-column label="工资单名称">
        <template slot-scope="scope">
          <span class="clickColor" @click="getDetail(scope.row.code)">{{ scope.row.payName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="所属项目">
        <template slot-scope="scope">
          <span>{{ scope.row.name }}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column label=" 身份证">
        <template slot-scope="scope">
          <span v-if="scope.row.user">{{ scope.row.user.idCardNumber }}</span>
        </template>
      </el-table-column>
      <el-table-column label="二类户卡号">
        <template slot-scope="scope">
          <span>{{ scope.row.accountCode }}</span>
        </template>
      </el-table-column>
      <el-table-column label="开户时间">
        <template slot-scope="scope">
          <span>{{ scope.row.createTime }}</span>
        </template>
      </el-table-column>-->
    </el-table>
    <div class="pagination">
      <span class="clickColor">共{{ total }}条</span>
      <span class="clickColor" style="margin:0 10px">每页{{ pageSize }}条</span>
      <el-pagination
        background
        :current-page.sync="pageNumber"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-size="pageSize"
        prev-text="上一页"
        next-text="下一页"
        layout="prev,pager, next,jumper"
        :total="total"
      ></el-pagination>
      <el-dialog title="新建工资单" :visible.sync="addShow" width="50%" :before-close="handleClose">
        <el-form
          label-position="top"
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          class="ruleForm"
          label-width="110px"
        >
          <el-form-item label="项目名称" prop="name">
            <el-select style="width: 100%" filterable v-model="ruleForm.name" @change="ShrList">
              <el-option
                v-for="item in templateList"
                :key="item.code"
                :label="item.name"
                :value="item.name"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="工资单月份" prop="date">
            <el-date-picker
              v-model="ruleForm.date"
              type="month"
              placeholder="选择日期"
              prefix-icon="el-icon-date"
              format="yyyy-MM"
              style="width: 100%"
              @change="getCurrentMonth(ruleForm.date)"
            ></el-date-picker>
          </el-form-item>
        </el-form>
        <div style="padding: 10px 0">
          <span>下载工资表模板，并将信息按要求填写到模板</span>
        </div>
        <div>
          <el-button type="primary" size="mini" plain style="margin: 10px 0">
            <a
              href="https://file.lgbfss.com/hr/0359750b85539c75d99abb246dffde27/工资单模板.xlsx"
              style="color: #409eff; text-decoration: none"
            >下载</a>
          </el-button>
        </div>
        <div>
          <span>将填好的模板文件上传到系统中</span>
        </div>
        <div>
          <el-upload
            class="upload-demo"
            :action="action"
            :auto-upload="false"
            :multiple="false"
            :on-change="handleChange"
            :file-list="fileList"
            :on-success="successFile"
          >
            <el-button type="primary" size="mini" plain style="margin: 20px 0">上传</el-button>
          </el-upload>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addShow = false">取 消</el-button>
          <el-button type="primary" @click="submit('ruleForm')">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { api } from '../../api/base';
// import { parseJsonToTable } from '/src/lib/dev';
import XLSX from 'xlsx';
// import baseUrl from "../../config/config";
export const customerList = api()('customer.list.json');
// export const bankCardListAll = api()(
//   "bank.card.list.all.json"
// );
export default {
  data () {
    return {
      keywords: '',
      pageSize: 10,
      pageNumber: 1,
      total: 0,
      value: '',
      lt_createTime: '',
      addShow: false,
      action: '',
      gt_createTime: '',
      ruleForm: {
        name: '',
        date: '',

      },
      rules: {
        name: [
          { required: true, message: '请选择项目名称', trigger: 'change' }
        ],
        date: [
          { required: true, message: '请选择工资单月份', trigger: 'change' }
        ]
      },
      fileList: [],
      templateList: [],
      tableData: [{
        date: '2021-10',
        name: '中国炼油厂',
        payName: '基础工资',
        code: 'ds231649'
      },
      {
        date: '2021-11',
        name: '中国炼油厂',
        payName: '补发10月工资',
        code: 'hc321354'
      }
      ],
      filePath: '',
      data: []
    }
  },

  created () {
    this.action = window.location.origin + '/enterprise.upload.json';
    this.ShrList()
  },
  methods: {
    // async exportAll () {
    //   const params = {
    //     pageSize: 1000000,
    //     pageNumber: 1,
    //     keywords: this.keywords,
    //     cardType: "2",
    //     lt_createTime: this.lt_createTime,
    //     gt_createTime: this.gt_createTime
    //   };
    //   const res = await bankCardListAll(params)
    //   const _list = res.list.map((it) => {
    //     return {
    //       ...it,
    //     };
    //   });
    //   console.log(_list);
    //   parseJsonToTable(
    //     `工资单列表`,
    //     {
    //       姓名: 'name',
    //       月份: 'date',
    //       工资单名称: 'payName',
    //     },
    //     _list,
    //     true,
    //   );

    // },
    getAdd () {
      this.addShow = true
    },
    getCurrentMonth (date) {
      this.ruleForm.date =
        date.getFullYear() + '-' + (date.getMonth() + 1);
      console.log(this.ruleForm.date);
    },
    getDetail (code) {
      this.$router.push({
        path: '/system/payroll/detail',
        query: { code },
      });
    },
    query () {
      this.pageNumber = 1;
      this.loadData();
    },
    getrq (date) {
      console.log(date);
      this.gt_createTime = date[0].getFullYear() + '-' + (date[0].getMonth() + 1) + '-' + (date[0].getDate()) + ' 00:00:00';
      this.lt_createTime = date[1].getFullYear() + '-' + (date[1].getMonth() + 1) + '-' + (date[1].getDate()) + ' 23:59:59';
      this.loadData()
    },
    getLocalTime (nS) {
      console.log(new Date(parseInt(nS) * 1000).toLocaleDateString());
      return this.gt_createTime = new Date(parseInt(nS) * 1000).toLocaleDateString().replace(/:\d{1,2}$/, ' ');
    },
    format (nS) {
      return this.lt_createTime = new Date(parseInt(nS) * 1000).toLocaleDateString().replace(/:\d{1,2}$/, ' ');
    },
    handleSizeChange (val) {
      this.loading = true;
      this.pageSize = val;
      this.loadData();
    },
    handleCurrentChange (val) {
      this.loading = true;
      this.pageNumber = val;
      this.loadData();
    },
    handleClose () {
      this.dialogVisible = false;
      this.bmdList = this.$store.state.badMaker.nameList;
    },
    handleChange (param) {
      console.log(param);
      this.fileList = [];
      // this.fileName = param.name;
      this.file2Xce(param).then(item => {
        if (item[0].sheet.length === 0) {
          return this.$message.error('传入表单无数据！');
        }
        const result = item[0].sheet.every(
          it => it['姓名'] === undefined || it['银行卡号'] === undefined
        );
        if (result) return this.$message.error('请完善表格信息');
        if (item && item.length > 0) {
          if (item[0] && item[0].sheet && item[0].sheet.length) {
            const dataArray = item[0].sheet.map(it => ({
              name: (it['姓名'] + '').replace(/\s+/g, ''),
              idCardNumber: (it['身份证号'] + '').replace(/\s+/g, ''),
              accountCode: (it['银行卡号'] + '').replace(/\s+/g, ''),
              basePay: (it['基本工资'] + '').replace(/\s+/g, ''),
              overtimePay: (it['加班工资'] + '').replace(/\s+/g, ''),
              oldPension: (it['养老'] + '').replace(/\s+/g, ''),
              medicalCare: (it['医疗'] + '').replace(/\s+/g, ''),
              Injury: (it['工伤'] + '').replace(/\s+/g, ''),
              Unemployment: (it['失业'] + '').replace(/\s+/g, ''),
              birth: (it['生育'] + '').replace(/\s+/g, ''),
              Deduction: (it['扣款'] + '').replace(/\s+/g, ''),
              netSalary: (it['实发工资'] + '').replace(/\s+/g, ''),
              outerTrade_amount: (it['总工资'] + '').replace(/\s+/g, ''),
            }));
            this.data = dataArray || [];
            if (this.data.some(it => it === 'undefined')) {
              return this.$message.error('请完善上传名单信息');
            }
            console.log(this.data);

          }
        }
      });
    },
    file2Xce (file) {
      return new Promise(function (resolve, reject) {
        console.log(reject);
        const reader = new FileReader();
        reader.onload = function (e) {
          const data = e.target.result;
          this.wb = XLSX.read(data, {
            type: 'binary'
          });
          const result = [];
          this.wb.SheetNames.forEach(sheetName => {
            result.push({
              sheetName: sheetName,
              sheet: XLSX.utils.sheet_to_json(this.wb.Sheets[sheetName])
            });
          });
          resolve(result);
        };
        reader.readAsBinaryString(file.raw);
      });
    },
    successFile (res, fileList) {
      console.log(fileList, 1111);

      this.filePath = res.data;
    },
    async ShrList () {
      const result = await customerList({
        keywords: '',
        pageNumber: 1,
        pageSize: 1000,

      });
      console.log(result);
      this.templateList = result.list;
    },
    submit (formName) {

      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this.data.length === 0) {
            this.$message({
              message: '请上传工资单',
              type: 'error'
            });
          }
          else {
            console.log(this.ruleForm);
            this.addShow = true
          }

        } else {
          console.log('error submit!!');
          return false;
        }
      });

    },
    async loadData () {
      // const params = {
      //   pageSize: this.pageSize,
      //   pageNumber: this.pageNumber,
      //   keywords: this.keywords,
      //   cardType: "2",
      //   lt_createTime: this.lt_createTime,
      //   gt_createTime: this.gt_createTime
      // };
      // const res = await bankCardListAll(params)
      // this.tableData = res.list
      // this.total = res.total;
    },
  },
  mounted () {
    var now = new Date(); //当前日期 
    var nowMonth = now.getMonth(); //当前月 
    var nowYear = now.getFullYear(); //当前年 
    //本月的开始时间
    var monthStartDate = new Date(nowYear, nowMonth, 1);
    //本月的结束时间
    var monthEndDate = new Date(nowYear, nowMonth + 1, 0);
    this.gt_createTime = monthStartDate.getFullYear() + '-' + (monthStartDate.getMonth() + 1) + '-' + (monthStartDate.getDate()) + ' 00:00:00';
    this.lt_createTime = monthEndDate.getFullYear() + '-' + (monthEndDate.getMonth() + 1) + '-' + (monthEndDate.getDate()) + ' 23:59:59';
    this.value = [this.gt_createTime, this.lt_createTime]
    this.loadData()
  },
}
</script>
<style lang="scss">
</style>